import { ChangeEvent, useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import cx from 'classnames';
import styles from './food-provider-form.module.scss';
import { useBreakpoints, useTranslation } from 'hooks';
import { Button, CustomButton, Text, AlertBanner } from 'components';
import { useFoodProviderContext } from 'context/food-provider-context';
import Status from 'constants/Status';
import Lang from 'constants/Locale';
import { Colors } from 'types/color.type';
import FoodProviderFormHeader from './header';
import FoodProviderStoreSelect from './store-select';
import FoodProviderSingleFields from './single-fields';
import OrderOptionCards from './order-option-cards';
import { REACT_APP_CF_HELP_EMAIL } from 'config/env';
import { ModalType } from 'constants/ModalType';
import { useModalContext } from 'context/modal-context';
import {
  validateFoodProviderImage,
  validateOrderMethods,
  validateOrderOptions,
  validateProperty,
  validateUnit,
  validateUrl,
  ValidationError,
} from 'helpers/validator';
import { InputValidation } from 'constants/InputValidation';
import { TextFieldTypes } from 'enums/FieldTypes';

const galleryImages = ['/images/Food-Provider-DefaultImage.jpg'];

const MAX_ORDER_OPTIONS = 6;

export enum FieldTypes {
  Image = 'image',
  Text = 'text',
}

export type FormItemLanguageProps = {
  label: string;
  value?: string;
  setValue?: React.Dispatch<React.SetStateAction<string>>;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  cta?: string;
  modalTitle?: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  validation?: InputValidation | TextFieldTypes;
};

export type FormItemProps = {
  title: string;
  subtitle: string;
  [Lang.en]: FormItemLanguageProps;
  [Lang.fr]: FormItemLanguageProps;
  type?: FieldTypes;
  gallery?: string[];
};

type ModalConfigOptions = {
  modalKey: string;
  handleAccept: () => void | Promise<void>;
  confirmButtonText: string;
  cancelButtonText: string;
  acceptButtonClassName: string;
  t: any;
  setShowModal: React.Dispatch<React.SetStateAction<boolean>>;
  isMobile: boolean;
};

const getModalBody = (modalKey: string, t: any, isMobile: boolean) => (
  <>
    <Text
      type={isMobile ? 'body' : 'bodyLg'}
      color={Colors.CFDarkGrey}
      className={styles.modalSubmitBody}
    >
      {t(`${modalKey}.body`)}
    </Text>{' '}
    <a
      href={`mailto:${REACT_APP_CF_HELP_EMAIL}`}
      target="_blank"
      rel="noopener noreferrer"
      className={styles.modalSubmitBodyLink}
    >
      {REACT_APP_CF_HELP_EMAIL}
    </a>
    {'.'}
  </>
);

const getModalConfig = ({
  modalKey,
  handleAccept,
  confirmButtonText,
  cancelButtonText,
  acceptButtonClassName,
  t,
  setShowModal,
  isMobile,
}: ModalConfigOptions) => {
  const titleKey = t(`${modalKey}.title`);
  const title = `${titleKey}?`;

  return {
    type: ModalType.Generic,
    title,
    body: getModalBody(modalKey, t, isMobile),
    handleClose: () => setShowModal(false),
    handleAccept,
    confirmButtonText,
    cancelButtonText,
    cancelButtonClassName: styles.modalSubmitButton,
    acceptButtonClassName,
    footerClassName: styles.modalSubmitFooter,
    reverseButtonOrder: !isMobile,
    showCloseButton: !isMobile,
  };
};

export const ReadOnlyButton = ({
  title,
  setIsEditing,
  isDisabled = false,
}: {
  title: string;
  setIsEditing: React.Dispatch<React.SetStateAction<boolean>>;
  isDisabled?: boolean;
}) => (
  <CustomButton
    text={title}
    variant="cf-text"
    alt=""
    disabled={isDisabled}
    iconName="plusBlue"
    iconHeight="24"
    iconWidth="24"
    onClick={() => setIsEditing(true)}
    className={styles.readOnlyButton}
    textCustomStyle={styles.readOnlyButtonText}
  />
);

const FoodProviderForm = () => {
  const { t } = useTranslation(['common', 'error']);

  const { isMobile } = useBreakpoints();
  const { showModal, setShowModal, setAndShowModal } = useModalContext();
  const [modalStatus, setModalStatus] = useState<string>('');
  const {
    foodProviderToCreate,
    setFoodProviderToCreate,

    selectedFoodProvider,
    setSelectedFoodProvider,

    selectedOrderOptions,
    orderOptionsToCreate,
    orderOptionsToDelete,

    orderServiceOptions,

    isEditing,
    setIsEditing,

    incompleteOrderOptionCards,
    setIncompleteOrderOptionCards,

    isLoading,
    setIsLoading,

    foodProviderRequester,
    fetchFoodProviders,

    newImageFile,
    newFrenchImageFile,

    handleSubmit: handleSubmitFoodProvider,

    fetchProperties,
    fetchUnits,
    handleAvailableStores,
  } = useFoodProviderContext();

  const [showUnitSelectors, setShowUnitSelectors] = useState<boolean>(false);

  const totalOrderOptions =
    selectedOrderOptions.length + orderOptionsToCreate.length + incompleteOrderOptionCards;

  useEffect(() => {
    let timeoutId: number;

    if (showModal) {
      setModalStatus('');
    } else {
      timeoutId = window.setTimeout(() => {
        setModalStatus(t('common:generic_modal.dialog_closed'));
      }, 1000);
    }
    return () => {
      timeoutId && clearTimeout(timeoutId);
    };
  }, [showModal]);

  useEffect(() => {
    setShowUnitSelectors(isEditing && (!selectedFoodProvider?.sys?.id || !!foodProviderToCreate));
  }, [selectedFoodProvider]);

  // TODO: Modify Form Items to be a more uniform schema which can determine what type of field to
  // Render, e.g., an Asset, an Input Field, etc.
  // Add in Validation as an optional field which allows us to specify one of the validations within the validation fn.
  const formItems: FormItemProps[] = [
    {
      type: FieldTypes.Image,
      title: t('food_provider_view.image.title'),
      subtitle: t('food_provider_view.image.subtitle'),
      gallery: galleryImages,
      [Lang.en]: {
        label: t('food_provider_view.image.language_title', {
          language: t('english'),
        }),
        value: selectedFoodProvider?.imageUrl?.[Lang.en],
        validation: InputValidation.LONG_TEXT_WEBSITE,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            imageUrl: {
              ...selectedFoodProvider?.imageUrl,
              [Lang.en]: e.target.value,
            },
          });
        },
        cta: t('food_provider_view.image.cta'),
        modalTitle: t('food_provider_image_modal.title', {
          language: t('english'),
        }),
      },
      [Lang.fr]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.image.language_title', {
                language: t('french'),
              }),
            })
          : t('food_provider_view.image.language_title', {
              language: t('french'),
            }),
        value: selectedFoodProvider?.imageUrl?.[Lang.fr],
        validation: InputValidation.LONG_TEXT_WEBSITE,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            imageUrl: {
              ...selectedFoodProvider?.imageUrl,
              [Lang.en]: e.target.value,
            },
          });
        },
        cta: t('food_provider_view.image.cta'),
        modalTitle: t('food_provider_image_modal.title', {
          language: t('french'),
        }),
      },
    },
    {
      title: t('food_provider_view.menu_link.title'),
      subtitle: t('food_provider_view.menu_link.subtitle'),
      [Lang.en]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.menu_link.cta', {
                language: t('english'),
              }),
            })
          : t('food_provider_view.menu_link.cta', {
              language: t('english'),
            }),
        value: selectedFoodProvider?.menuUrl,
        required: false,
        validation: InputValidation.SYMBOL_WEBSITE,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            menuUrl: e.target.value,
          });
        },
      },
      [Lang.fr]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.menu_link.cta', {
                language: t('french'),
              }),
            })
          : t('food_provider_view.menu_link.cta', {
              language: t('french'),
            }),
        value: selectedFoodProvider?.menuUrlFrench,
        required: false,
        validation: InputValidation.SYMBOL_WEBSITE,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            menuUrlFrench: e.target.value,
          });
        },
      },
    },
    {
      title: t('food_provider_view.reservation_link.title'),
      subtitle: t('food_provider_view.reservation_link.subtitle'),
      [Lang.en]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.reservation_link.cta', {
                language: t('english'),
              }),
            })
          : t('food_provider_view.reservation_link.cta', {
              language: t('english'),
            }),
        value: selectedFoodProvider?.reserveTableUrl,
        required: false,
        validation: InputValidation.SYMBOL_WEBSITE,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            reserveTableUrl: e.target.value,
          });
        },
      },
      [Lang.fr]: {
        label: isEditing
          ? t('food_provider_edit.optional_field', {
              fieldValue: t('food_provider_view.reservation_link.cta', {
                language: t('french'),
              }),
            })
          : t('food_provider_view.reservation_link.cta', {
              language: t('french'),
            }),
        value: selectedFoodProvider?.reserveTableUrlFrench,
        validation: InputValidation.SYMBOL_WEBSITE,
        required: false,
        onChange: (e: ChangeEvent<HTMLInputElement>) => {
          setSelectedFoodProvider({
            ...selectedFoodProvider,
            reserveTableUrlFrench: e.target.value,
          });
        },
      },
    },
  ];

  const handleSubmit = async () => {
    // Check for errors
    // Don't proceed if there are errors
    const englishImageFileValidation = await validateFoodProviderImage(
      selectedFoodProvider?.imageUrl?.[Lang.en],
      newImageFile
    );
    const frenchImageFileValidation = await validateFoodProviderImage(
      selectedFoodProvider?.imageUrl?.[Lang.fr],
      newFrenchImageFile,
      false
    );

    const allOrderOptionsToKeep = [...selectedOrderOptions, ...orderOptionsToCreate];

    const errors: (ValidationError | undefined)[] = [
      validateProperty(selectedFoodProvider?.property?.[Lang.en]?.sys?.id),
      validateUnit(selectedFoodProvider?.unit?.[Lang.en]?.sys?.id),
      englishImageFileValidation, // hasEnglishImage
      validateUrl(selectedFoodProvider?.menuUrl, false), // hasMenuUrl
      validateUrl(selectedFoodProvider?.reserveTableUrl, false), // hasReservationUrl
      frenchImageFileValidation, // hasFrenchImage
      validateUrl(selectedFoodProvider?.menuUrlFrench, false), // hasMenuUrlFrench
      validateUrl(selectedFoodProvider?.reserveTableUrlFrench, false), // hasReservationUrlFrench
      validateOrderOptions(selectedOrderOptions, orderOptionsToCreate, orderOptionsToDelete), // order option totals
      ...allOrderOptionsToKeep.map<ValidationError | undefined>((orderOption) => {
        // Order Options all have valid fields?
        const orderMethodErrors = validateOrderMethods(orderOption.orderMethods);
        const englishServiceLinkErrors = validateUrl(
          orderOption.orderUrl?.[Lang.en]?.fields?.WebLink?.[Lang.en],
          true,
          InputValidation.LONG_TEXT_WEBSITE
        ); // English Service URL
        const frenchServiceLinkErrors = validateUrl(
          orderOption.orderUrl?.[Lang.en]?.fields?.WebLink?.[Lang.fr],
          false,
          InputValidation.LONG_TEXT_WEBSITE
        ); // French Service URL
        return (
          orderMethodErrors ?? englishServiceLinkErrors ?? frenchServiceLinkErrors ?? undefined
        );
      }),
    ];

    const hasErrors = errors.some((validation) => validation !== undefined);

    if (hasErrors) {
      toast.error(t('error:food_provider_form.general.MISSING'));
    } else {
      showSubmitModal();
    }
  };

  const showSubmitModal = () =>
    setAndShowModal(
      getModalConfig({
        modalKey: 'submit_food_provider_modal',
        handleAccept: async () => {
          setShowModal(false);
          setIsLoading(true);
          const res = await handleSubmitFoodProvider?.(false);
          if (res?.id) {
            toast.success(t('submit_review_succesfful'));

            // Finally: fetch food providers
            fetchFoodProviders?.();

            // Re-fetch properties and units
            await fetchProperties?.();
            await fetchUnits?.();
          }
          setIsLoading(false);
        },
        confirmButtonText: t('submit_food_provider_modal.submit'),
        cancelButtonText: t(
          isMobile ? 'generic_modal.close_label' : 'submit_food_provider_modal.continue_editing'
        ),
        acceptButtonClassName: styles.modalSubmitButton,
        t,
        setShowModal,
        isMobile,
      })
    );

  const showDeleteModal = () =>
    setAndShowModal(
      getModalConfig({
        modalKey: 'delete_food_provider_modal',
        handleAccept: async () => {
          if (foodProviderToCreate) {
            // Deletes the unsaved draft
            setFoodProviderToCreate(null);
            setShowModal(false);
            setIsEditing(false);

            toast.success(t('delete_successful'));
          } else if (
            !!selectedFoodProvider &&
            foodProviderRequester &&
            selectedFoodProvider.sys?.id
          ) {
            foodProviderRequester
              .discard(selectedFoodProvider.sys?.id)
              .then(() => {
                toast.success(t('delete_successful'));
              })
              .finally(async () => {
                await fetchFoodProviders?.();
                await fetchProperties?.();
                await fetchUnits?.();
                await handleAvailableStores?.();
              });
            setShowModal(false);
          }
        },
        confirmButtonText: t('delete_food_provider_modal.delete'),
        cancelButtonText: t('cancel'),
        acceptButtonClassName: styles.modalDeleteButton,
        t,
        setShowModal,
        isMobile,
      })
    );

  const handleSaveDraft = async () => {
    setIsLoading(true);
    const res = await handleSubmitFoodProvider?.(true);
    if (res?.id) {
      toast.success(t('food_provider_edit.saved_draft_successfully'));

      // Finally: fetch food providers
      fetchFoodProviders?.();
    }
    setIsLoading(false);
  };

  return (
    <>
      {isMobile && (
        <CustomButton
          variant="cf-text"
          iconName="chevronLeftBlue"
          alt="back icon"
          text={t('food_provider_left_panel.return_to_my_stores')}
          onClick={() => {
            setSelectedFoodProvider(null);
          }}
          textCustomStyle={styles.backText}
          iconHeight="16"
          iconWidth="16"
          customStyle={styles.back}
        />
      )}
      <FoodProviderFormHeader
        handleSubmitModal={handleSubmit}
        handleDeleteModal={showDeleteModal}
        handleSaveDraft={handleSaveDraft}
      />
      <div className={cx(styles.form, { [styles.formReadonly]: !isEditing })}>
        {showUnitSelectors && (
          <div className={styles.item}>
            <div className={styles.itemHeading}>
              <Text type="h5" color={Colors.MidnightBlue}>
                {t('food_provider_view.property.title')}
              </Text>
            </div>
            <div className={styles.itemFields}>
              <FoodProviderStoreSelect />
            </div>
          </div>
        )}
        {formItems?.map((formItem) => (
          <div key={`form-item-${formItem.title}`} className={styles.item}>
            <div className={styles.itemHeading}>
              <Text type="h5" color={Colors.MidnightBlue}>
                {formItem.title}
              </Text>
              <Text type="body" color={Colors.CFDarkGrey} className={styles.itemHeadingSubtitle}>
                {formItem.subtitle}
              </Text>
            </div>
            <div
              className={cx(styles.itemFields, {
                [styles.itemFieldsImage]: formItem?.type === FieldTypes.Image,
              })}
            >
              <FoodProviderSingleFields formItem={formItem} />
            </div>
          </div>
        ))}
        <div className={styles.item}>
          <div className={styles.itemHeading}>
            <Text type="h5" color={Colors.MidnightBlue}>
              {t('food_provider_view.order_options.title')}
            </Text>
            <Text type="body" color={Colors.CFDarkGrey}>
              {t('food_provider_view.order_options.subtitle')}
            </Text>
          </div>
          <div className={styles.itemFields}>
            <OrderOptionCards textInputClassName={styles.textInput} />
          </div>
          {isEditing &&
            orderServiceOptions &&
            totalOrderOptions < MAX_ORDER_OPTIONS &&
            totalOrderOptions < orderServiceOptions?.length && (
              <Button
                disabled={isLoading}
                variant="outline"
                onClick={() => setIncompleteOrderOptionCards(incompleteOrderOptionCards + 1)}
              >
                {t('food_provider_edit.add_service_cta')}
              </Button>
            )}
          {isEditing && totalOrderOptions === MAX_ORDER_OPTIONS && (
            <AlertBanner
              ariaLabel={t('common:food_provider_edit.service_maximum')}
              ariaLive="assertive"
              role="alert"
              title={t('common:food_provider_edit.service_maximum')}
              displayIcon
            />
          )}
        </div>
      </div>
      {isEditing && (
        <div className={styles.bottom}>
          <div className={styles.bottomLine} />
          <div className={styles.bottomButtons}>
            {(selectedFoodProvider?.status as Status) !== Status.Published && (
              <Button
                onClick={handleSaveDraft}
                size="stretch"
                variant="outline"
                disabled={
                  isLoading ||
                  !selectedFoodProvider?.property?.[Lang.en]?.sys?.id ||
                  !selectedFoodProvider?.unit?.[Lang.en]?.sys?.id
                }
              >
                {t('food_provider_edit.save_draft')}
              </Button>
            )}

            <Button
              size="stretch"
              disabled={isLoading}
              onClick={() => {
                if (!isEditing) {
                  setIsEditing(true);
                } else {
                  handleSubmit();
                }
              }}
            >
              {t('food_provider_edit.submit')}
            </Button>
          </div>
        </div>
      )}
      {/* Modal close accessibility announcement */}
      <div aria-live="polite" aria-atomic="true" className="sr-only">
        {modalStatus}
      </div>
    </>
  );
};

export default FoodProviderForm;
