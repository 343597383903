import cx from 'classnames';
import { Text } from 'components';
import { useTranslation } from 'react-i18next';
import { useAppContext } from 'context/app-context';
import styles from './tabs.module.scss';
import { StateFilterOptions, useFoodProviderContext } from 'context/food-provider-context';
import { Colors } from 'types/color.type';

const Tabs = () => {
  const { t } = useTranslation();
  const { appliedState, setAppliedState } = useFoodProviderContext();
  const { locale } = useAppContext();
  console.log(locale);

  return (
    <div className={styles.tabContainer}>
      {Object.values(StateFilterOptions).map((state) => (
        <button
          onClick={() => {
            if (state !== appliedState) setAppliedState(state);
          }}
          key={`tab-${state}`}
          className={cx(styles.tab, { [styles.tabActive]: appliedState === state })}
        >
          <Text
            type="h5"
            color={Colors.MidnightBlue}
            className={`${styles.tabText} ${locale === 'fr-CA' ? styles.tabTextFr : ''}`}
          >
            {t(`food_provider_left_panel.state_filter_tabs.${state}`)}
          </Text>
        </button>
      ))}
    </div>
  );
};

export default Tabs;
